import * as React from 'react';
import { Avatar, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import styled from 'styled-components';
import { IconPencil } from '@tabler/icons-react';
import { ChangeEvent, useRef } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { okaoAxios } from '../../../helpers/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dish } from '../../../pages/Menu/types';
import { store } from '../../../redux/store';
import { AdminRestaurant } from '../../../pages/Restaurant/types';

const AvatarContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row'
})

export const ProfileUserImageEditIcon = styled(IconPencil).attrs(() => {
  return {
    size: 18,
    stroke: 2,
  }
})(() => ({}))

const EditIconButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: -17,
  backgroundColor: 'white',
  padding: 9,
  boxShadow: '0px 0px 4px 0px #33333340',
  '&:hover': {
    backgroundColor: 'white',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 18,
  },
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function renderAvatar(
  params: GridRenderCellParams<{ row: Dish | AdminRestaurant; }, any, any>,
) {
  if (params.row == null) {
    return '';
  }

  const dispatch = useAppDispatch()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const handleClick = (): void => {
    fileInputRef.current?.click()
  }

  const updateDishImage = createAsyncThunk(
    'api/updateDishImage',
    async (formData: FormData) => {
      const auth = store.getState().secure.accessToken ?? '';
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/upload/restaurant/dish/${params.row?.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      const data = {
        ...response.data,
        headers: { ...response.headers },
      }
      return data
    },
  )

  const updateRestaurantImage = createAsyncThunk(
    'api/updateRestaurantImage',
    async (formData: FormData) => {
      const auth = store.getState().secure.accessToken ?? '';
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/upload/restaurant/${params.row?.alias}/main`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      const data = {
        ...response.data,
        headers: { ...response.headers },
      }
      return data
    },
  )

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if ((event?.target?.files) != null) {
      const file = event?.target?.files?.[0]

      const formData = new FormData()
      formData.append('file', file)
      if(params.row?.price === undefined){
        const result = await dispatch(updateRestaurantImage(formData))
        if (updateRestaurantImage.fulfilled.match(result)) {
          params.row.imageurl = result.payload.imageUrl
        }
      }else{
        const result = await dispatch(updateDishImage(formData))
        if (updateDishImage.fulfilled.match(result)) {
          params.row.imageurl = result.payload.imageUrl
        }
      }
    }
  }

  return (
    <AvatarContainer>
      <div style={{}}>
        <Avatar
          src={
            params.row?.imageurl ??
            params.row?.imageurl ??
            'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
          }
          sx={{
            width: 35,
            height: 35,
            display: 'inline-block',
          }}
          alt="Profile Image"
        />
      </div>
      <div style={{}}>
        <EditIconButton onClick={handleClick}>
          <ProfileUserImageEditIcon />
        </EditIconButton>
      </div>
    <input
      ref={fileInputRef}
      type="file"
      accept="image/*"
      style={{ display: 'none' }}
      onChange={(event) => {
        void handleFileChange(event)
      }}
    />
    </AvatarContainer>
  );
}