import styled from "styled-components";
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    gap: 55.9375rem;

    width: 77.625rem;
    height: 3.625rem;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`

export const Title = styled.div`
    width: 4.4375rem;
    height: 1.5rem;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    /* Black/60 */

    color: #45464E;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const TopMenu = styled.div`
    position: relative;
    right: 10rem;
`
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 2rem;

    width: 77.625rem;
    height: 49.625rem;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    background-color: #fff;
    margin-top: 1rem;
`

export const ContentBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 4.8125rem;

    width: 81.9375rem;
    height: 44rem;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const Metadata = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 48px;

    width: 81.9375rem;
    min-height: 37rem;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const TopFields = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 4.8125rem;

    width: 81.9375rem;
    height: 5rem;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const MiddleFields = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 4.8125rem;

    width: 81.9375rem;
    height: 5rem;


    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
`

export const BottomFields = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 4.8125rem;

    width: 81.9375rem;
    height: 5rem;


    /* Inside auto layout */

    flex: none;
    order: 3;
    flex-grow: 0;
`

export const Menus = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 4.8125rem;

    width: 81.9375rem;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

export const MenuUrl = styled.div`
    inline-size: 12rem;
    overflow-wrap: break-word;
`

export const ProfileUserAvatarContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      mb: {
        xs: '24px',
        md: '40px',
      },
      mt: {
        xs: '16px',
        md: '0px',
      },
      position: 'inherit',
    },
  }
})(() => ({}))

export const AvatarContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
})

export const EditIconButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: -17,
  backgroundColor: 'white',
  padding: 9,
  boxShadow: '0px 0px 4px 0px #33333340',
  '&:hover': {
    backgroundColor: 'white',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 18,
  },
})

export const AdditionalImages = styled.div`
    display: flex;
    flex-direction: row;
    order: 2;
`